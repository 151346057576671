import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase } from 'firebase/database';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { collection, doc, Firestore, getFirestore } from 'firebase/firestore';

import {
    Auth,
    getAuth,
} from 'firebase/auth';
import { Functions, getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyAWZak-1dscz29LzgGc7_6ketgR2_aLqfs",
    authDomain: "ando-dev.firebaseapp.com",
    databaseURL: "https://ando-dev-default-rtdb.firebaseio.com",
    projectId: "ando-dev",
    storageBucket: "ando-dev.appspot.com",
    messagingSenderId: "343837484117",
    appId: "1:343837484117:web:a0c8d263fe2433b1c920dc",
    measurementId: "G-NX8LYH604Z"
};

let firebaseApp: FirebaseApp | null = null;
let firebaseAuth: Auth | null = null;
let firebaseDatabase: Database | null = null;
let firebaseStorage: FirebaseStorage | null = null;
let firestore: Firestore | null = null;
let firebaseFunctions: Functions | null = null;

const firebaseRegion = process.env.firebaseRegion || 'us-central1';

export function getFirebaseApp() {
    if (firebaseApp == null || firebaseDatabase == null || firebaseAuth == null || firebaseStorage == null || firebaseFunctions == null) {
        firebaseApp = initializeApp(firebaseConfig);
        firebaseDatabase = getDatabase(firebaseApp);
        firebaseAuth = getAuth(firebaseApp);
        firebaseStorage = getStorage(firebaseApp);
        firestore = getFirestore(firebaseApp);
        firebaseFunctions = getFunctions(firebaseApp, firebaseRegion);
    }
    return {
        firebaseApp,
        firebaseDatabase,
        firebaseAuth,
        firebaseStorage,
        firestore,
        firebaseFunctions,
    };
}

export function getStylesCollectionRef() {
    return firestore && collection(firestore, 'Styles');
}

export function getIllustrationsCollectionRef() {
    return firestore && collection(firestore, 'Illustrations');
}

export function getIllustrationRef(id: string) {
    return firestore && doc(firestore, `Illustrations/${id}`);
}

export function getUserRef() {
    if (firebaseAuth && firestore) {
        const uid = firebaseAuth.currentUser?.uid
        if (uid == null) {
          return null;
        }
        return doc(firestore, `users/${uid}`);
    }
    return null;
}