import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes ,
  Route,
  useLocation,
  Location,
} from 'react-router-dom'
import { Home } from './Home';
import { FigmaLogin } from './Login';
import { EditorBeta } from './EditorBeta';
import {StylePage, StylesPage} from './Styles';
import {AppContext} from './AppContext'
import { IllustrationModal, IllustrationPage } from './Styles/Illustration';
import { PricingPage } from './Pricing';

function AppRoutes() {
  const location = useLocation();

  const state = location.state as {backgroundLocation?: Location};

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<Home/>}/>
        <Route path="/figmaLogin" element={<FigmaLogin/>}/>
        <Route path="/editor" element={<EditorBeta/>}/>
        <Route path="/styles" element={<StylesPage/>}/>
        <Route path="/styles/:styleId" element={<StylePage/>}/>
        <Route path="/illustration/:id" element={<IllustrationPage/>}/>
        <Route path="/pricing" element={<PricingPage/>}/>
      </Routes>
      {
        state?.backgroundLocation && (
          <Routes>
            <Route path="/illustration/:id" element={<IllustrationModal/>}/>
          </Routes>
        )
      }
    </>
  )
}

function App() {
  return (
    <AppContext>
      <BrowserRouter>
        <AppRoutes/>
      </BrowserRouter>
    </AppContext>
  );
}

export default App;
