import React from 'react';
import { AppContainer } from './AppContainer';
import { useFirebaseAuthContext } from './Hooks/useFirebaseAuth';
import { NavBarMain } from './NavBar';
import { SubscriptionPlans } from './Subscriptions';

export function PricingPage() {
    const userType = useFirebaseAuthContext()?.userType;
    return (
        <AppContainer>
            <NavBarMain/>
            <div className='w-full flex flex-col items-center flex-center'>
                <div className='my-32 title-font'>
                    <div className='text-5xl mb-12'>
                        Pricing
                    </div>
                </div>
                <SubscriptionPlans isPro={userType === 'pro'}/>
            </div>
        </AppContainer>
    )
}