export type Illustration = {
    prompt: string,
    imageUrl: string,
    tags?: string[],
    styles?: string[],
}

export type StyleExamples = string[];

export type Style = {
    name: string,
    imageUrl: string,
};

export type StyleWithExamples = Style & {
    examples?: StyleExamples,
};

export type Styles = Record<string, Style>;

export function isObjectStyle(object: any): object is Style {
    return object && typeof(object.name) === 'string' && typeof(object.imageUrl) === 'string';
}

export function isObjectIllustration(object: any): object is Illustration {
    return object && typeof(object.prompt) === 'string' && typeof(object.imageUrl) === 'string';
}