import React, { useCallback, useState } from 'react';
import { AppContainer } from '../AppContainer';
import { NavBarMain } from '../NavBar';
import * as Dialog from '@radix-ui/react-dialog';
import { getStyleName, useStylesContext } from './StylesContext';
import { Illustration } from './types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Cross1Icon } from '@radix-ui/react-icons';

const labelContainerName = 'flex flex-col md:flex-row mb-6'
const labelClassName = 'mb-2 md:mb-0 sm:w-[100px] md:w-[150px] text-zinc-500 select-none truncate';

function IllustrationInner({
    id,
    headerElement,
    className='',
}: {
    id?: string,
    headerElement?: React.ReactNode,
    className?: string,
}) {
    // Get the illustration
    const [illustration, setIllustration] = useState<Illustration>();
    
    const {styles, preloadIllustration} = useStylesContext();
    
    React.useEffect(() => {
        if (id) {
            preloadIllustration?.(id).then((illustration) => {
                if (illustration) {
                    setIllustration(illustration);
                }
            });
        }
    }, [id, preloadIllustration]);

    const getStyleNameCallback = useCallback((styleId: string) => getStyleName(styleId, styles), [styles]);

    return (
        <div className={`w-full h-full flex flex-col items-center text-lg text-left ${className}`}>
            <div className='w-full flex flex-row mb-6'>
                <div className='mr-4 text-zinc-500 select-none'>
                    Illustration
                </div>
                <div className='grow truncate'>
                    {illustration?.prompt}
                </div>
                {headerElement}
            </div>
            <div className='w-full h-[800px] lg:min-h-[512px] flex flex-col lg:flex-row'>
                <div 
                    className='relative w-full h-[50%] mb-4 lg:mb-0 lg:h-auto lg:w-[50%] bg-zinc-800/20 rounded-md flex justify-center items-center bg-contain bg-no-repeat bg-center'
                    style={{
                        backgroundImage: illustration?.imageUrl ? `url(${illustration.imageUrl})` : '',
                    }}
                >
                </div>
                <div className='w-full lg:w-auto grow flex flex-col lg:px-8 py-2'>
                    <div className={labelContainerName}>
                        <div className={labelClassName}>Prompt</div>
                        <div className='flex-1 pr-2 mb-4 md:mb-0'>{illustration?.prompt}</div>
                        <div
                            className="w-fit h-fit px-4 py-2 mr-4 rounded-md bg-zinc-800 active:bg-neutral-900 text-zinc-400 hover:text-zinc-300 select-none transition-colors shadow hover:shadow-md cursor-pointer"
                            onClick={() => {
                                if (illustration?.prompt) {
                                    navigator.clipboard?.writeText(illustration.prompt).catch((err) => {
                                        console.error(err);
                                    });
                                }
                            }}
                        >
                            Copy
                        </div>
                    </div>
                    <div className={`${labelContainerName} md:items-center`}>
                        <div className={labelClassName}>Style</div>
                        <div className='grow'>{illustration?.styles?.map((styleId) => {
                            const name = getStyleNameCallback(styleId);
                            if (name) {
                                return (
                                    <Link
                                        to={`/styles/${styleId}`}
                                        key={styleId}
                                        className="w-fit px-4 py-2 mr-4 rounded-md bg-zinc-800 text-zinc-400 hover:text-zinc-300 select-none transition-colors shadow hover:shadow-md cursor-pointer"
                                    >
                                        {name}
                                    </Link>
                                )
                            }
                            return null;
                        })}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function IllustrationModal() {
    const [isOpen, setIsOpen] = useState(true);
    const {id} = useParams();
    const navigate = useNavigate();
    
    const onOpenChange = React.useCallback((open: boolean) => {
        if (!open) {
            navigate(-1);
        }
        setIsOpen(open);
    }, [navigate]);
    

    return (
        <div className='fixed top-0 left-0 w-screen h-screen overflow-hidden flex items-center justify-center'>
            <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
                <Dialog.Content className='w-[80%] 2xl:w-[1200px] focus-visible:outline-none'>
                    <div className='w-full bg-zinc-900 border-zinc-800 focus-visible:outline-none border focus-visible:border-zinc-700 rounded-lg shadow-xl overflow-hidden'>
                        <IllustrationInner 
                            id={id}
                            className="px-8 py-6 bg-zinc-800/30"
                            headerElement={
                                <div 
                                    className='p-2 ml-2 rounded-full bg-zinc-800 hover:bg-zinc-700 text-zinc-500 hover:text-zinc-300 transition-colors'
                                    onClick={() => onOpenChange(false)}
                                >
                                    <Cross1Icon/>
                                </div>
                            }
                        />
                    </div>
                </Dialog.Content>
            </Dialog.Root>
        </div>
    )
}

export function IllustrationPage() {
    const {id} = useParams();
    return (
        <AppContainer>
            <NavBarMain/>
            <div className='w-full'>
                <IllustrationInner 
                    id={id}
                />
            </div>
        </AppContainer>
    )
}