import React from 'react';


type CustomAuthSessionState = {
    authSessionId: string | null,
    privateKey: string | null,
    setAuthSessionId?: (id: string) => void,
    setPrivateKey?: (key: string) => void,
};

const CustomAuthSessionContext = React.createContext<CustomAuthSessionState>({authSessionId: null, privateKey: null});

export function CustomAuthSessionProvider({
    children,
}: {
    children: React.ReactNode,
}) {
    const [authSessionId, setAuthSessionId] = React.useState<string | null>(null);
    const [privateKey, setPrivateKey] = React.useState<string | null>(null);

    return (
        <CustomAuthSessionContext.Provider value={{
            authSessionId,
            setAuthSessionId,
            privateKey,
            setPrivateKey,
        }}>
            {children}
        </CustomAuthSessionContext.Provider>
    )
}

export function useCustomAuthSession() {
    return React.useContext(CustomAuthSessionContext);
}