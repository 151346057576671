import React from 'react';
import { FirebaseAuthProvider } from './Hooks/useFirebaseAuth';
import { StylesContextProvider } from './Styles/StylesContext';

export function AppContext({
    children,
}: {
    children: React.ReactNode,
}) {
    return (
        <FirebaseAuthProvider>
            <StylesContextProvider>
                {children}
            </StylesContextProvider>
        </FirebaseAuthProvider>
    )
}