import React, { useRef, useState } from 'react';
import GoogleIcon from './Icons/GoogleIcon';
import { 
    GoogleAuthProvider,
    signInWithPopup,
    User,
} from 'firebase/auth';
import {
    httpsCallable,
} from 'firebase/functions';
import {
    doc as firestoreDoc,
    onSnapshot,
} from 'firebase/firestore'
import { useSearchParams } from "react-router-dom";
import { getFirebaseApp } from './firebaseApp';
import { useFirebaseAuthContext, useFirebaseAuthUser, UserType } from './Hooks/useFirebaseAuth'
import { useSignOutCallback } from './Hooks/useSignOutCallback';
import { CustomAuthSessionProvider, useCustomAuthSession } from './Hooks/useCustomAuthSession';
import { AppContainer } from './AppContainer';
import { NavBarContainer, navBarButtonClassName } from './NavBar';
import { Subscriptions } from './Subscriptions';
import { useUserCredits } from './credits';
import * as Toast from '@radix-ui/react-toast';
import { DAILY_SIGNUP_FREE_CREDITS } from './constants';

getFirebaseApp();

const googleProvider = new GoogleAuthProvider();
googleProvider?.addScope('email');

export function continueWithGoogle() {
    const { firebaseAuth } = getFirebaseApp();
    if (firebaseAuth) {
        signInWithPopup(firebaseAuth, googleProvider)
            .then(() => {
                console.log('Signed in');
            }).catch((error) => {
                console.log(error);
            });
    }
}

function LoginToast({
    open,
    title,
    description,
    setOpen,
}: {
    title: string,
    description?: string,
    open: boolean,
    setOpen: (value: boolean,) => void,
}) {
    return (
        <Toast.Provider swipeDirection='down'>
            <Toast.Root open={open} onOpenChange={setOpen} className="mb-12 w-fit text-sm flex flex-row justify-center items-center px-3 py-2 bg-zinc-700/60 rounded-md border border-zinc-600/50 text-sm text-left text-zinc-400 leading-relaxed shadow-lg">
                <div className='flex-col items-center mr-8'>
                    <Toast.Title className='font-semibold text-zinc-100'>
                        {title}
                    </Toast.Title>
                    <Toast.Description className='font-normal mt-1'>
                        {description}
                    </Toast.Description>
                </div>
                <Toast.Action
                    altText='OK'
                    className='px-3 py-2 bg-lime-500 hover:bg-lime-400 text-lg text-zinc-900 rounded-md title-font transition-colors'
                >
                    OK
                </Toast.Action>
                <Toast.Close />
            </Toast.Root>
            <Toast.Viewport className='fixed flex flex-row justify-center w-full bottom-0 left-0'/>
        </Toast.Provider>
    )
}

function LoginInner() {
    return (
        <div className='flex flex-col justify-center items-center my-4 bg-zinc-900 border border-zinc-700 p-8 rounded-lg'>
            <div className='pb-6 text-2xl title-font text-zinc-100'>Get Started</div>
            <button 
                type="button"
                className='rounded-md border border-zinc-500 hover:border-zinc-100 transition-colors my-2 px-2 py-2 flex flex-row items-center cursor-pointer text-zinc-300 hover:text-white'
                onClick={continueWithGoogle}
            >
                <GoogleIcon/>
                <span className='mr-2'>
                    Continue with Google
                </span>
            </button>
        </div>
    );
}

const buttonStyles = 'bg-blue-500 hover:bg-blue-400 focus:outline focus:outline-2 focus:outline-blue-600 text-slate-100 select-none cursor-pointer rounded-md';

function SectionContainer({
    children,
}: {
    children: React.ReactNode,
}) {
    return (
        <div className='flex flex-col items-center justify-center border border-slate-100 rounded-md px-8 py-8 my-4 mx-4 w-full sm:w-[500px]'>
            {children}
        </div>
    )
}

function SubmittingButton() {
    return (
        <div className='flex flex-row items-center justify-center ml-2 px-4 py-2 bg-blue-400 cursor-wait select-none rounded-md text-slate-100'>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>
                Submitting
            </span>
        </div>
    )
}

function InviteCodeForm({
    inviteCode: inviteCodeInit = '',
}: {
    inviteCode?: string,
}) {

    const [inviteCode, setInviteCode] = React.useState(inviteCodeInit);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const { firebaseApp, firebaseAuth, firebaseFunctions } = getFirebaseApp();
        if (firebaseApp && firebaseAuth && firebaseFunctions) {
            const uid = firebaseAuth.currentUser?.uid;
            if (uid) {
                setIsSubmitting(true);
                const applyInviteCode = httpsCallable<{inviteCode: string, inviteCodeType?: string}, {code?: number, message?: string}>(firebaseFunctions, 'applyInviteCode');
                applyInviteCode?.({
                    inviteCode,
                    inviteCodeType: 'beta0',
                }).then(ret => ret?.data).then((data) => {
                    console.log(`Get apply invite code result: ${JSON.stringify(data)}`);
                    setIsSubmitting(false);
                    if (data?.code !== 200) {
                        // Show error message
                        setErrorMessage(data.message || 'Unknown error. Please try again.');
                    }
                }).catch((error) => {
                    console.warn(error);
                    setIsSubmitting(false);
                });
            } else {
                // Show error message
                setErrorMessage('Please sign in before applying the invite code.');
            }
        }
    }, [inviteCode]);

    return (
        <SectionContainer>
            <span>
                Enter your invite code below for free beta acess:
            </span>
            <form 
                className='w-full flex flex-row my-4 items-center justify-center'
                onSubmit={handleSubmit}
            >
                <input className='grow rounded-md border-slate-100 px-2 py-2 my-2 border border-slate-100 focus:outline-blue-500' placeholder={'Invitation Code'} onChange={(e) => setInviteCode(e.currentTarget.value)}/>
                {
                    isSubmitting 
                    ? 
                        <SubmittingButton/>
                    : 
                        <input type="submit" value="Submit" className={`ml-2 px-4 py-2 ${buttonStyles}`}/>
                }
            </form>
        </SectionContainer>
    )
}

function SubscribeOneTime() {
    return (
        <SectionContainer>
            <div>
                No invite code? Get one month trial for $15 (no auto-renew).
            </div>
            <div className='py-2 text-slate-300'>
                Gonna pay the server bills somehow ...
            </div>
            <div className={`px-4 py-2 my-4 ${buttonStyles}`}>
                Subscribe
            </div>
            {/* <StripeCheckoutForm/> */}
        </SectionContainer>
    )
}

function SubscribeInternal() {
    return (
        <>
            {/* <InviteCodeForm/> */}
            <SubscribeOneTime/>
        </>
    )
}

function WelcomeSubscriber() {
    return (
        <SectionContainer>
            <span>Welcome</span>
        </SectionContainer>
    )
}

function SubcriptionSection() {
    const [isQuotaExpired, setIsQuotaExpired] = React.useState(true);

    // Subscribe to changes in user subscription status
    React.useEffect(() => {
        const { firestore, firebaseAuth } = getFirebaseApp();
        const uid = firebaseAuth.currentUser?.uid;
        if (firestore && uid) {
            const userRef = firestoreDoc(firestore, 'users', uid);
            return onSnapshot(userRef, (userSnapshot) => {
                if (userSnapshot?.exists()) {
                    const quotas = userSnapshot.data();
                    const endTimestamp = quotas?.quotaEndTime;
                    if (endTimestamp != null) {
                        // Check the time
                        const endTime = endTimestamp.toDate?.();
                        console.log(`Quota end time: ${endTime}; Timestamp: ${endTimestamp}`);
                        if (endTime && endTime instanceof Date && endTime > new Date()) {
                            setIsQuotaExpired(false);
                        } else {
                            console.log('End time is expired, reset.');
                            setIsQuotaExpired(true);
                        }
                    } else {
                        setIsQuotaExpired(true);
                    }
                }
            });
        }
    }, []);

    return isQuotaExpired ? <SubscribeInternal/> : <WelcomeSubscriber/>;
}

function LoginSuccess({
    user,
    userType,
    privateKey,
    authSessionId,
}: {
    user: User,
    userType: UserType,
    privateKey?: string | null,
    authSessionId?: string | null,
}) {
    const { firebaseFunctions } = getFirebaseApp();


    const [toastOpen, setToastOpen] = useState(false);
    const toastTitleRef = useRef('');
    const toastDescriptionRef = useRef('');

    const credits = useUserCredits({uid: user.uid});

    React.useEffect(() => {
        if (user.uid) {
            const handleUserLogin = httpsCallable(firebaseFunctions, 'handleUserLogin');
            handleUserLogin?.().then((res) => {
                console.log('Handled user signin');
                console.log(res.data);
                if (userType !== 'pro' && (res.data as any)?.creditsUpdated) {
                    toastTitleRef.current = `Congrats! You received ${DAILY_SIGNUP_FREE_CREDITS} credits.`;
                    toastDescriptionRef.current = `Every day when you sign-in, you get ${DAILY_SIGNUP_FREE_CREDITS} free credits.`;
                    setToastOpen(true);
                }
            });
        }
    }, [user, userType, firebaseFunctions]);

    React.useEffect(() => {

        if (privateKey && authSessionId) {
            console.log(`Call server to create a custom auth token for session ${authSessionId}`);

            const updateAuthSessionData = httpsCallable<{ privateKey?: string, authSessionId?: string, }, any>(firebaseFunctions, 'updateAuthSessionData');

            updateAuthSessionData?.({
                privateKey,
                authSessionId,
            }).then((result) => {
                console.log('Updated the auth session data and obtained the following result:');
                console.log(result);
            });

        }
    }, [privateKey, authSessionId, firebaseFunctions]);

    return (
        <>
            <NavBarContainer>
                <SignOutButton/>
            </NavBarContainer>
            <div className='w-full flex flex-col items-center flex-center'>
                <div className='my-36 title-font'>
                    <div className='text-5xl mb-12'>
                        Hi, let's design some <span className='text-lime-500'>cool</span> stuff.
                    </div>
                    <div className='text-3xl text-zinc-100/50'>
                        Please open Figma to start designing.
                    </div>
                </div>
                <Subscriptions credits={credits}/>
            </div>
            <LoginToast
                open={toastOpen}
                setOpen={setToastOpen}
                title={toastTitleRef.current}
                description={toastDescriptionRef.current}
            />
        </>
    )
}

function SignOutButton() {
    const signOutCallback = useSignOutCallback();
    return (
        <div 
            className={navBarButtonClassName}
            onClick={signOutCallback}
        >
            Sign out
        </div>
    )
}

function FigmaLoginInternal() {
    const {user, userType} = useFirebaseAuthContext();

    const [searchParams] = useSearchParams();

    const authSession = useCustomAuthSession();

    React.useEffect(() => {

        if (authSession && searchParams) {
            
            authSession.setAuthSessionId?.(searchParams.get('authSessionId') || '');

            authSession.setPrivateKey?.(searchParams.get('key') || '');

        }

    }, [authSession, searchParams]);

    if (user) {
        return <LoginSuccess user={user} userType={userType} authSessionId={authSession.authSessionId} privateKey={authSession.privateKey}/>;
    }
    return (
        <>
            <NavBarContainer></NavBarContainer>
            <div className='grow w-screen flex flex-row items-center justify-center bg-zinc-900'>
                <LoginInner/>
            </div>
        </>
    )
}

export function FigmaLogin() {
    return (
        <AppContainer>
            <CustomAuthSessionProvider>
                <FigmaLoginInternal/>
            </CustomAuthSessionProvider>
        </AppContainer>
    )
}