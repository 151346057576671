import React from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { getFirebaseApp } from '../firebaseApp';
import { onSnapshot, collection, query, where } from 'firebase/firestore';

export type UserType = 'free' | 'pro';

type FirebaseAuthContextState = { 
    user: User | null,
    userType: UserType,
    subscriptionEndDate: Date | null,
};

const FirebaseAuthContext = React.createContext<FirebaseAuthContextState>({ user: null, userType: 'free', subscriptionEndDate: null });

export function FirebaseAuthProvider({
    children,
}: {
    children: React.ReactNode,
}) {
    const [user, setUser] = React.useState<User | null>(null);
    const [userType, setUserType] = React.useState<UserType>('free');
    const [subscriptionEndDate, setEndDate] = React.useState<Date | null>(null);

    const value = { 
        user,
        userType,
        subscriptionEndDate,
    };


    React.useEffect(() => {
        const { firebaseAuth } = getFirebaseApp();
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            (user) => {
                setUser(user);
                if (!user || user.uid == null) {
                    setUserType('free');
                    setEndDate(null);
                }
            },
        );
        return unsubscribe;
    }, []);

    React.useEffect(() => {
        const {firebaseAuth, firestore} = getFirebaseApp();
        if (!firestore || !firebaseAuth?.currentUser) {
            return;
        }
        const uid = firebaseAuth.currentUser.uid;
        onSnapshot(
            query(collection(firestore, `customers/${uid}/subscriptions`), where('status', 'in', ['trialing', 'active'])),
            async (snapshot) => {
                if (snapshot.empty) {
                    return;
                }
                const subscription = snapshot.docs[0]?.data();
                if (subscription) {
                    setUserType('pro');
                    const timestamp = subscription.current_period_end;
                    if (timestamp) {
                        const date = new Date(timestamp.seconds*1000)
                        setEndDate(date);
                    }
                }
            }
        );
    }, [user]);

    return (
        <FirebaseAuthContext.Provider value={value}>
            {children}
        </FirebaseAuthContext.Provider>
    );
}

export function useFirebaseAuthContext() {
    return React.useContext(FirebaseAuthContext)
}

export function useFirebaseAuthUser() {
    return useFirebaseAuthContext()?.user;
}