import { useState, useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { getUserRef } from './firebaseApp';

export function useUserCredits({
    uid,
}: {
    uid: string | undefined,
}) {
    const [credits, setCredits] = useState(0);
    useEffect(() => {
        const userRef = getUserRef();
        if (userRef) {
            return onSnapshot(userRef, (snapshot) => {
                const user = snapshot?.data();
                setCredits(user?.credits || 0);
            });
        }
    }, [uid]);
    return credits;
}