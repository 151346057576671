import { onSnapshot, collection, addDoc, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from './CheckIcon';
import { DAILY_SIGNUP_FREE_CREDITS, MAX_FREE_CREDITS_PER_MONTH, MONTHLY_STARTING_FREE_CREDITS } from './constants';
import { getFirebaseApp } from './firebaseApp';
import { useFirebaseAuthContext } from './Hooks/useFirebaseAuth';
import * as HoverCard from '@radix-ui/react-hover-card';
import { QuestionMarkIcon } from '@radix-ui/react-icons';

async function openBillingPortal() {
    const {firebaseFunctions} = getFirebaseApp();
    if (!firebaseFunctions) {
        return;
    }
    const createPortalLink = httpsCallable(firebaseFunctions, 'ext-firestore-stripe-payments-createPortalLink');
    
    if (!createPortalLink) {
        return;
    }
    const  response = await createPortalLink({returnUrl: `${window.location.origin}/figmaLogin`});
    const url = (response?.data as any)?.url;
    if ((url)) {
        window.location.assign(url);
    }
}

async function handleSubscribePro() {
    
    const {firebaseAuth, firestore} = getFirebaseApp();

    if (!firebaseAuth || !firestore) {
      return;
    }
    
    const uid = firebaseAuth.currentUser?.uid;

    if (!uid) {
        return;
    }

    const selectedPrice = {
        price: 'price_1LbUw5FOuafSx4Zomlv1Jmp9',
        quantity: 1,
    }

    const loginUrl = `${window.location.origin}/figmaLogin`;

    const checkoutSession = {
        allow_promotion_codes: true,
        line_items: [selectedPrice],
        success_url: loginUrl,
        cancel_url: loginUrl,
        metadata: {
          key: 'value',
        },
      };

      const collectionRef = collection(firestore, `customers/${uid}/checkout_sessions`);

      const docRef = await addDoc(collectionRef, checkoutSession);
      
      // Wait for the CheckoutSession to get attached by the extension
      onSnapshot(docRef, (snap) => {
        const data = snap.data();
        if (data) {
            const { error, url } = data;
            if (error) {
              // Show an error to your customer and then inspect your function logs.
              alert(`An error occured: ${error.message}`);
            }
            if (url) {
              window.location.assign(url);
            }
        }
      });
}

const proButtonClassName = 'flex flex-row items-cente justify-center w-full mt-4 px-8 py-4 font-bold rounded-full bg-lime-500 select-none transition-colors'

function GetProButton() {
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <div 
            className={`${proButtonClassName} ${isLoading ? 'cursor-wait' : 'cursor-pointer hover:bg-lime-400'}`}
            onClick={(e) => {
                e.preventDefault();
                if (!isLoading) {
                    setIsLoading(true);
                    handleSubscribePro();
                }
            }}
        >
            {
                isLoading 
                ? 
                <>
                    <div className='loader mr-4'/>
                    <span>
                        Processing ...
                    </span>
                </>
                : 
                <span>
                    Get Pro
                </span>
            }
            
        </div>
    )
}


function SubscriptionsInternal({
    isPro = false,
    credits = undefined,
}: {
    credits?: number,
    isPro?: boolean,
}) {
    return (
        <div className='grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap-8 w-full max-w-[700px]'>
            <div className='flex flex-col items-start justify-start rounded-lg bg-zinc-800 text-zinc-300 min-h-[500px] p-8'>
                <div className='w-full flex flex-row items-center title-font'>
                    <div className='grow text-left text-3xl'>
                        Free
                    </div>
                    {credits !== undefined && <div className='text-lg text-zinc-300/50 px-2 py-1 rounded-md bg-zinc-700/50 select-none'>
                        {credits}
                    </div>}
                </div>
                <div className='w-full flex flex-col items-center justify-center my-12'>
                    <div className='text-6xl title-font mb-6'>
                        $0
                    </div>
                    <span className='text-zinc-400'>Forever</span>
                    <div className='w-full mt-4 px-8 py-4 rounded-full bg-zinc-700/30'>
                        {isPro ? "Free plan." : "Your current plan."}
                    </div>
                </div>
                <div className='w-full flex justify-center items-center'>
                </div>
                <div>
                    Features:
                </div>
                <div className='flex flex-col items-start pt-8'>
                    <div className='flex flex-row items-center justify-center mb-4'>
                        <CheckIcon className='text-lime-500' size={18}/>
                        <span className='ml-2'>
                            Up to {MAX_FREE_CREDITS_PER_MONTH} designs / month.
                        </span>
                        <HoverCard.Root openDelay={0}>
                            <HoverCard.Trigger className='ml-2 p-1 text-zinc-400 hover:text-zinc-300 bg-zinc-700/50 hover:bg-zinc-600 rounded-full transition-colors shadow-md'>
                                <QuestionMarkIcon width={16}/>
                            </HoverCard.Trigger>
                            <HoverCard.Content sideOffset={5}>
                                <div className='flex flex-col py-2 bg-zinc-700 rounded-md border border-zinc-600/50 text-sm text-left leading-relaxed shadow-lg'>
                                    <div className='border-b border-zinc-600/50 px-4 pb-2 mb-2'>
                                        + <span className='text-lime-500 pr-2'>{MONTHLY_STARTING_FREE_CREDITS}</span> designs at the start of each month<br/> 
                                        + <span className='text-lime-500 pr-2'>{DAILY_SIGNUP_FREE_CREDITS}</span> designs per daily sign-in
                                    </div>
                                    <span className='text-zinc-400 px-4'>
                                        Credits are reset every month.
                                    </span>
                                </div>
                            </HoverCard.Content>
                        </HoverCard.Root>
                    </div>
                    <div className='flex flex-row items-center justify-center mb-4'>
                        <CheckIcon className='text-lime-500' size={18}/>
                        <span className='ml-2'>
                            Up to 1k upscale.
                        </span>
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-start justify-start rounded-lg bg-zinc-200 text-zinc-900 min-h-[500px] p-8 shadow-2xl shadow-lime-500/50'>
                <div className='text-3xl title-font'>
                    Pro
                </div>
                <div className='w-full flex flex-col items-center justify-center my-12'>
                    <div className='text-6xl title-font mb-6'>
                        $18
                    </div>
                    <span>Per month</span>
                    {isPro ? <Link className={`${proButtonClassName} cursor-pointer hover:bg-lime-400`} to="/figmaLogin">Your current plan.</Link> : <GetProButton/>}
                </div>
                <div className='w-full flex justify-center items-center'>
                </div>
                <div>
                    Features:
                </div>
                <div className='flex flex-col items-start pt-8'>
                    <div className='flex flex-row items-center justify-center mb-4'>
                        <CheckIcon className='text-lime-500' size={18}/>
                        <span className='ml-2'>
                            <span className='font-bold'>Unlimited</span> designs.
                        </span>
                    </div>
                    <div className='flex flex-row items-center justify-center mb-4'>
                        <CheckIcon className='text-lime-500' size={18}/>
                        <span className='ml-2'>
                            Up to 2k upscale.
                        </span>
                    </div>
                    <div className='flex flex-row items-center justify-center mb-4'>
                        <CheckIcon className='text-lime-500' size={18}/>
                        <span className='ml-2'>
                            All latest features.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function SubscriptionPlans({
    isPro,
}: {
    isPro: boolean,
}) {
    return (
        <SubscriptionsInternal
            isPro={isPro}
        />
    );
}

export function FreeUser({
    credits = 0,
}: {
    credits?: number,
}) {
    return (
        <SubscriptionsInternal
            isPro={false}
            credits={credits}
        />
    );
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

function getDateString(date: Date | null | undefined) {
    if (!date) return '';
    const day = date?.getDate();
    const month = date?.getMonth();
    const year = date?.getFullYear();
    return `${monthNames[month]} ${day}, ${year}`
}

function ProUser({
    endDate,
} :{
    endDate?: Date | null,
}) {
    const dateString = getDateString(endDate);
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <div className='w-full flex flex-col items-start justify-center bg-zinc-800 px-12 py-4 rounded-md mx-2 max-w-[350px]'>
            <div className='w-full flex flex-col text-left'>
                <div className='title-font text-5xl my-4 select-none text-white hover:text-lime-500 transition-colors'>
                    Pro
                </div>
                <div className='my-2 text-zinc-300'>
                    Thank you for supporting Ando!
                </div>
                {
                    endDate 
                    ? 
                    <div className='flex flex-col mt-16 text-zinc-300'>
                        <span>
                        Your subscription ends on
                        </span>
                        <span className='my-4 px-8 py-4 text-lg text-center text-zinc-400 bg-zinc-700/50 rounded-full'>{dateString}</span>
                    </div>
                    : null
                }
            </div>
            <div className='flex flex-row items-center justify-center w-full mt-2 mb-8 px-8 py-4 title-font rounded-full text-lg bg-lime-500 hover:bg-lime-400 text-zinc-900 select-none cursor-pointer transition-colors' onClick={(e) => {
                e.preventDefault();
                if (!isLoading) {
                    openBillingPortal();
                    setIsLoading(true);
                }
            }}>
                {
                    isLoading 
                    ? 
                    <>
                        <div className='loader mr-4'/>
                        <span>
                            Processing ...
                        </span>
                    </>
                    :
                    <span>
                        Open user portal
                    </span>
                }
            </div>
        </div>
    )

}

export function Subscriptions({
    credits,
}: {
    credits?: number,
}) {
    const {
        userType, 
        subscriptionEndDate: endDate
    } = useFirebaseAuthContext();

    return (
        userType === 'pro' ? <ProUser endDate={endDate}/> : <FreeUser credits={credits}/>
    );
}