import { getDoc, getDocs, query, where } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { getIllustrationRef, getIllustrationsCollectionRef, getStylesCollectionRef } from '../firebaseApp';
import {isObjectStyle, isObjectIllustration, StyleWithExamples, Styles,  Illustration, } from './types'

function loadStylesFromFirestore() {
    const styleColletionsRef = getStylesCollectionRef();
    if (styleColletionsRef) {
        return getDocs(styleColletionsRef).then((snapshot) => {
            const styles: Styles = {}
            snapshot?.forEach((styleDoc) => {
                const style = styleDoc.data();
                if (isObjectStyle(style)) {
                    styles[styleDoc.id] = style;
                }
            })
            return styles;
        });
    }
    return Promise.resolve();
}

function getStyleExamplesQuery(styleId: string) {
    const illustrationsRef = getIllustrationsCollectionRef();
    if (illustrationsRef) {
        return query(
            illustrationsRef,
            where('styles', 'array-contains', styleId),
        );
    }
    return null;
}

export function loadStyleExamplesFromFirestore(styleId: string) {
    const query = getStyleExamplesQuery(styleId);
    if (query) {
        return getDocs(query)?.then((querySnapshot) => {
            const examples: Record<string, Illustration> = {};
            querySnapshot?.forEach((exampleDoc) => {
                const example = exampleDoc.data();
                if (isObjectIllustration(example)) {
                    examples[exampleDoc.id.trim()] = example;
                }
            });
            return examples;
        });
    }
    return Promise.resolve(undefined);
}

export function loadIllustrationFromFirestore(id: string) {
    const illustrationRef = getIllustrationRef(id);
    if (illustrationRef) {
        return getDoc(illustrationRef).then((snapshot) => {
            if (snapshot) {
                const illustration = snapshot.data();
                if (isObjectIllustration(illustration)) {
                    return illustration;
                }
            }
        });
    }
    return Promise.resolve(undefined);
}

type StylesContext = {
    styles: Record<string, StyleWithExamples>,
    illustrations: Record<string, Illustration>,
    preloadStyleExamples?: (styleId: string) => Promise<Record<string, Illustration> | undefined>,
    preloadIllustration?: (id: string) => Promise<Illustration | undefined>,
};

export const stylesContext = React.createContext<StylesContext>({
    styles: {},
    illustrations: {},
})

export function useStylesContext() {
    return useContext(stylesContext);
}

const styleNameMap: Record<string, string> = {
    '3dIcon': '3D Icon',
    '3dIllustration': '3D Illustration',
}

export function getStyleName(styleId: string, styles: Record<string, StyleWithExamples>) {
    const name = styleNameMap[styleId];
    if (name) {
        return name;
    }
    return styles[styleId]?.name;
}

export function StylesContextProvider({
    children,
}: {
    children: React.ReactNode,
}) {
    const [styles, setStyles] = useState<Record<string, StyleWithExamples>>({});
    const [illustrations, setIllustrations] = useState<Record<string, Illustration>>({});
    useEffect(() => {
        loadStylesFromFirestore().then((newStyles) => {
            if (newStyles) {
                setStyles(newStyles);
            }
        });
    }, []);
    return (
        <stylesContext.Provider
            value={{
                styles,
                illustrations,
                preloadStyleExamples: (styleId) => {
                    if (!styleId || styles[styleId] == null) {
                        return Promise.resolve(undefined);
                    }
                    styleId = styleId.trim();
                    if (styles[styleId]?.examples) {
                        const output: Record<string, Illustration> = {};
                        styles[styleId].examples?.forEach((id) => {
                            id = id.trim();
                            output[id] = illustrations[id];
                        });
                        return Promise.resolve(output);
                    }
                    return loadStyleExamplesFromFirestore(styleId).then((examples) => {
                        // styles[styleId].examples = examples;
                        setStyles((styles) => {
                            return {
                                ...styles,
                                [styleId]: {
                                    ...(styles[styleId]),
                                    examples: examples && Object.keys(examples)?.sort((id0, id1) => {
                                        const prompt0: string = examples[id0].prompt;
                                        const prompt1: string = examples[id1].prompt;
                                        return prompt0.localeCompare(prompt1);
                                    }),
                                }
                            }
                        });
                        setIllustrations((illustrations) => {
                            return {
                                ...illustrations,
                                ...examples,
                            }
                        });
                        return examples;
                    });
                },
                preloadIllustration: (id) => {
                    if (!id) {
                        return Promise.resolve(undefined);
                    }
                    id = id.trim();
                    if (illustrations[id]) {
                        return Promise.resolve(illustrations[id]);
                    }
                    console.log(`Get illustration ${id} from firestore`);
                    return loadIllustrationFromFirestore(id).then((illustration) => {
                        if (illustration) {
                            setIllustrations((illustrations) => {
                                return {
                                    ...illustrations,
                                    [id]: illustration,
                                }
                            });
                        }
                        return illustration;
                    });
                }
            }}
        >
            {children}
        </stylesContext.Provider>
    )
}