import React, { useCallback, useRef, useState } from 'react';
import { AppContainer } from './AppContainer';
import { useCustomAuthSession } from './Hooks/useCustomAuthSession';
import { useFirebaseAuthUser } from './Hooks/useFirebaseAuth';
import { NavBarMain } from './NavBar';
import {useDropzone} from 'react-dropzone'
import { CrossIcon } from './CrossIcon';

const validImageFileType = new Set(['image/png', 'image/jpeg']);

const apiEndpoint = "tando-render-load-balancer-915178270.us-east-1.elb.amazonaws.com"

export function EditorBeta() {

    const user = useFirebaseAuthUser();
    const authSession = useCustomAuthSession();
    
    const imageFileRef = useRef<File | null>(null);

    const [prompt, setPrompt] = useState<string>('');

    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const [progress, setProgress] = useState(0);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const numFiles = acceptedFiles?.length || 0;
        for (let i = 0; i < numFiles; ++i) {
            const file = acceptedFiles[i];
            if (file && validImageFileType.has(file.type)) {
                console.log('Set image url');
                setImageUrl(URL.createObjectURL(file));
                break;
            }
        }
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'image/png': ['.png', '.PNG'],
            'image/jpeg': ['.jpg', '.jpeg', '.JPG', '.JPEG'],
        }
    })
    
    return (
        <AppContainer>
            <NavBarMain/>
            <div className='w-full text-lg md:text-xl'>
                <div className='my-16'>
                    Editor (Beta)
                </div>
                <div className='flex flex-col md:flex-row w-full'>
                    <div className='md:w-1/2 md:mr-4 flex flex-col items-center flex-center'>
                        <div className='w-full flex flex-col flex-center items-center mb-4'>
                            <div className='w-full text-left'>
                                Prompt
                            </div>
                            <textarea
                                className='w-full px-4 py-3 mx-8 my-6 text-zinc-100 bg-zinc-500/10 rounded-md focus:outline-none border border-zinc-500/20 hover:border-zinc-500/50 focus:border-zinc-500'
                                placeholder='a house'
                                value={prompt}
                                rows={3}
                                onChange={(e) => {
                                    const value = e?.currentTarget?.value;
                                    setPrompt(value || '');
                                }}
                            />
                        </div>
                        <div className='w-full flex flex-col flex-center items-center mb-4'>
                            <div className='w-full text-left'>
                                Image Reference
                            </div>
                            <div className='w-full flex flex-col md:flex-row md:h-80 mx-8 my-6'>
                                {
                                    imageUrl && 
                                    <div className='relative w-full md:w-1/2 h-80 md:h-full px-4 py-3 mr-4 mb-4 rounded-md bg-zinc-500/10 flex flex-col flex-center items-center focus:outline-none border border-zinc-500/20'>
                                        <CrossIcon 
                                            width={18} 
                                            className='absolute right-0 mr-3 text-zinc-500 hover:text-zinc-100 cursor-pointer'
                                            onClick={() => {
                                                setImageUrl(null);
                                            }}
                                        />
                                        <img className='object-cover my-4 h-full min-h-0 min-w-0 rounded-md' alt="preview" src={imageUrl}/>
                                    </div>
                                }
                                <div className='w-full md:w-1/2 h-80 md:h-full px-4 py-3 rounded-md bg-zinc-500/10 cursor-pointer flex flex-center items-center focus:outline-none border border-zinc-500/20 hover:border-zinc-500/50 focus:border-zinc-500' {...getRootProps()}>
                                    <input {...getInputProps()}/>
                                    <p className='w-full text-center text-zinc-500'>
                                        {isDragActive ?
                                        "Drop the image here ..." :
                                        `Click here to ${imageUrl ? "replace" : "upload"} image`}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex flex-row flex-center items-center mb-12 md:mb-0'>
                            <div 
                                className='grow bg-lime-500 hover:bg-lime-400 rounded-md px-4 py-3 text-zinc-900 select-none cursor-pointer transition-colors'
                                onClick={() => {
                                    if (user?.uid) {

                                    }
                                }}
                            >
                                {user?.uid ? "Render" : "Login to render"}
                            </div>
                        </div>
                    </div>
                    <div className='grow flex flex-col md:ml-4 flex flex-col'>
                        <div className='w-full text-left mb-6'>
                            Output
                        </div>
                        <div className='h-full min-h-[256px] rounded-md bg-zinc-500/10'>
                            
                        </div>
                    </div>
                </div>
            </div>
        </AppContainer>
    )
}