import { ChevronLeftIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContainer } from '../AppContainer';
import { NavBarContainer, NavBarMain } from '../NavBar';
import { useStylesContext } from './StylesContext';
import {Style} from './types';

const styleTemplateClassName = 'flex flex-col items-center p-6 rounded-md border border-zinc-500/20 hover:border-zinc-500/50 text-zinc-400 hover:text-zinc-100 focus:border-zinc-500 transition-colors cursor-pointer'

function getImage256Url(imageUrl: string) {
    // if (imageUrl) {
    //     const imagePaths = imageUrl.split('/');
    //     if (imagePaths.length >= 2) {
    //         imagePaths.splice(imagePaths.length - 1, 0, 'tr:w-512')
    //         const output = imagePaths.join('/');
    //         return output;
    //     }
    // }
    return imageUrl;
}

function SuggestStyle() {
    return (
        <a href="https://forms.gle/XYwD6WSDqPikBV4k9" target="_blank" rel="noopener noreferrer" className={`${styleTemplateClassName} bg-zinc-800/20 hover:bg-zinc-800/30`}>
            <div 
                className='relative w-full bg-zinc-800/10 rounded-md'
                style={{
                    paddingBottom: '100%',
                }}
            >
                <div className='absolute w-full h-full flex justify-center items-center text-zinc-800'>
                    <PlusCircledIcon 
                        className='w-1/5 h-1/5'
                    />
                </div>
            </div>
            <div className='w-full mt-6 text-left select-none' >
                Suggest Style
            </div>
        </a>
    )
}

function StyleTemplate({
    name,
    imageUrl,
}: Style) {
    return (
        <div 
            className={`${styleTemplateClassName}`}
        >
            <div 
                className='relative w-full rounded bg-cover bg-center'
                style={{
                    paddingBottom: '100%',
                    backgroundImage: imageUrl ? `url(${getImage256Url(imageUrl)})` : '',
                }}
            />
            <div className='w-full mt-6 text-left select-none truncate'>
                {name}
            </div>
        </div>
    )
}



function StyleNotFound() {
    return (
        <div>
            Style Not Found.
        </div>
    )
}

function StylePageInner({
    styleId,
}: {
    styleId: string,
}) {
    // Load the first 50 images for the given style
    const {styles, illustrations, preloadStyleExamples} = useStylesContext();

    const style = styles[styleId];
    const examples = style?.examples;

    useEffect(() => {
        preloadStyleExamples?.(styleId);
    }, [styleId, preloadStyleExamples]);
    
    const location = useLocation();

    return (
        <div className='w-full text-lg'>
            <div className='w-full h-8'>
                <Link to="/styles" className='w-[100px] mt-8 flex flex-row items-center text-zinc-300 hover:text-zinc-100 transition-colors'>
                    <ChevronLeftIcon className='mr-2'/>
                    Styles
                </Link>
            </div>
            <div className='py-20 title-font text-3xl font-bold'>
                {style?.name}
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4'>
                {examples?.map((id) => {
                    const illustration = illustrations[id];
                    return (
                    <Link
                        key={id}
                        to={`/illustration/${id}`}
                        state={{
                            backgroundLocation: location,
                        }}
                    >
                        <StyleTemplate
                            name={illustration.prompt}
                            imageUrl={illustration.imageUrl}
                        />
                    </Link>
                )})}
            </div>
        </div>
    )
}

export function StylePage() {
    const {styleId} = useParams();

    return (
        <AppContainer>
            <NavBarMain/>
            {
                styleId == null ?
                <StyleNotFound/> :
                <StylePageInner styleId={styleId}/>
            }
        </AppContainer>
    )
}

export function StylesPage() {
    const {styles} = useStylesContext();

    return (
        <AppContainer>
            <NavBarMain/>
            <div className='w-full text-lg text-zinc-300'>
                <div className='w-full h-8 mt-8'/>
                <div className='py-20 title-font text-3xl font-bold'>
                    Styles
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {styles && Object.entries(styles).map(([styleId, val]) => (
                        <Link
                            key={styleId}
                            to={`/styles/${styleId}`}
                        >
                            <StyleTemplate
                                {...val}
                            />
                        </Link>
                    ))}
                    <SuggestStyle/>
                </div>
            </div>
        </AppContainer>
    )
}